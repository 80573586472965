import Api from "../../../Shared/services/api";
import ApiUploader from "../../../Shared/services/apiUploader";
import { buildParams } from "../../../../plugins/helpers";

const baseUrl = "EngineeringFirms";
export default {
  get() {
    return Api().get(baseUrl);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  uploadImage(imageData, config) {
    return ApiUploader().post(`${baseUrl}/Image`, imageData, config);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  getById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  getHubspotDetails(companyId) {
    return Api().get(`${baseUrl}/HubspotCompaniesById` + `/${companyId}`);
  },
};
